import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HotelBasicInfoWithLocation } from './-MyBrandHotel';
import { HImg, HLink } from '~/components';

export interface OtherThemeListProps {
  hotels: HotelBasicInfoWithLocation[];
}

export interface OtherThemeListEmits {}

export interface OtherThemeListScopedSlots {}

@Component<OtherThemeListRef>({
  name: 'OtherThemeList',
  render() {
    const { hotels } = this;
    return (
      <div staticClass="my-hotels__list-wrapper__inner">
        <ul
          staticClass="my-hotels__list"
          class={{
            'my-hotels__list--less-than-4': hotels.length < 4,
            'my-hotels__list--less-than-2': hotels.length < 2,
          }}>
          {hotels.map((hotel) => {
            const { location, brandAddress, introduction } = hotel;
            const imgUrl =
              introduction.image && introduction.image.derived.md.url;

            return (
              <li key={hotel.slug} staticClass="my-hotels__list__item">
                {!!imgUrl && (
                  <HImg
                    staticClass="my-hotels__list__item__thumbnail"
                    src={this.$res.img(imgUrl)}
                    alt={hotel.name}
                  />
                )}
                <p staticClass="my-hotels__list__item__address">
                  {brandAddress}
                </p>
                <HLink
                  staticClass="my-hotels__list__item__link"
                  to={location.to}
                  href={location.href}
                  target={location.target}
                  rel={location.rel}>
                  {hotel.name}
                </HLink>
              </li>
            );
          })}
        </ul>
      </div>
    );
  },
})
export default class OtherThemeListRef
  extends Vue
  implements OtherThemeListProps {
  @Prop({ type: Array, required: true })
  readonly hotels!: HotelBasicInfoWithLocation[];
}

export const OtherThemeList = tsx
  .ofType<OtherThemeListProps, OtherThemeListEmits, OtherThemeListScopedSlots>()
  .convert(OtherThemeListRef);
